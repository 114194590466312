import {
  Timeclock,
  TimeclockChangeRequst,
  timeclockDefault,
  TimeclockEvent
} from "@/interfaces/timeclock";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { timeclockService } from "@/services/timeclock.service";
import { Callback, PageNavAction } from "@/types/types";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import addMinutes from "date-fns/addMinutes";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import differenceInMinutes from "date-fns/differenceInMinutes";
import isAfter from "date-fns/isAfter";
import {
  ActionsSubheaderComponent,
  ActionSubheader,
  PageTitleComponent,
  TableAction,
  TableComponent,
  TableFieldType,
  TableHeader,
  TableSuccessModalResponse
} from "helix-vue-components";
import chunk from "lodash/chunk";
import debounce from "lodash/debounce";
import findIndex from "lodash/findIndex";
import isEqual from "lodash/isEqual";
import { Validator } from "vee-validate";
import {
  Component,
  Inject,
  Prop,
  Provide,
  Vue,
  Watch
} from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { TimeEventType } from "../Timeclock.enum";
import { TimeclockEvents } from "../Timeclock.events";
import TimeclockEventModalComponent from "../timeclockForm/timeclockEvent/TimeclockEvent.component";
import Template from "./TimeclockChangeRequest.template.vue";

const namespace: string = "TimeclockModule";

@Component({
  components: {
    TableComponent,
    TimeclockEventModalComponent,
    ActionsSubheaderComponent,
    PageTitleComponent
  },
  mixins: [Template]
})
export default class TimeclockChangeRequest extends Vue {
  public get userItems() {
    return !!this.model.user
      ? [this.model.user, ...this.storeUsersItems]
      : this.storeUsersItems;
  }

  public get timeclockEventsParsed() {
    return this.model.timeclock_events.map(time => {
      return {
        ...time,
        registered_at: fnsFormatDate(
          new Date(time.registered_at),
          FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
        )
      };
    });
  }

  get pairedBreaks() {
    const breaks = this.model.timeclock_events.filter(element =>
      [TimeEventType.BREAK_TIME_IN, TimeEventType.BREAK_TIME_OUT].includes(
        element.event as TimeEventType
      )
    );
    const pairedBreaks = breaks.length % 2 === 0;
    return pairedBreaks;
  }
  @Prop({
    default: () => ({ ...timeclockDefault })
  })
  public currentModel!: Timeclock;
  public model: Timeclock = timeclockDefault;
  public i: number = 0;
  public unpairedBreaksMessage: string = "";
  public dateMenu = false;
  public headerActions: ActionSubheader[] = [
    {
      icon: "fal fa-check",
      action: this.saveTimeclockItem
    },
    {
      icon: "fal fa-times",
      action: this.cancel
    }
  ];

  // Employee autocomplete store
  @Getter("users", { namespace: "UserModule" })
  public storeUsersItems!: User[];
  @Getter("loading", { namespace: "UserModule" })
  public isLoading!: boolean;
  @Action("searchUsers", { namespace: "UserModule" })
  public searchUserAction!: Callback;

  public notes = "";

  public rules = {
    notes: (text: string) => (value: string) =>
      (!!value && value.length > 2) || text
  };

  // Timeclock store
  @Getter("currentTimeclock", { namespace })
  public current!: Timeclock | null;
  @Action("findTimeclock", { namespace })
  public findTimeclock!: Callback;
  @Action("deleteEvent", { namespace })
  public deleteEventAction!: Callback;
  @Action("saveTimeclock", { namespace })
  public saveTimeclock!: Callback;
  @Action("deleteTimeclock", { namespace })
  public deleteTimeclock!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  // Validator
  @Inject("$validator") public $validator!: Validator;
  @Provide()
  public validator = this.$validator;

  public managerPin: string = "";
  public itemForEdition: TimeclockEvent | null = null;
  public delayTimer: number | undefined = undefined;
  public searchUserField: string = "";

  public debounceUserSearch = debounce(async (userName: string) => {
    if (!userName || userName.length > 2) {
      await this.searchUserAction(userName);
    }
  }, 500);

  // datatable information
  public headers: TableHeader[] = [
    {
      value: "event",
      label: "event",
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: false
    },
    {
      value: "registered_at",
      label: "time",
      fieldComponent: TableFieldType.string,
      class: "tdt__headers__fieldLong",
      sortable: false
    }
  ];

  public generalActions: TableAction[] = [
    {
      icon: "fal fa-plus",
      action: (...arg: any[]) => {
        this.addEventModal();
      }
    }
  ];

  public rowActions: TableAction[] = [];

  public setRowActions() {
    this.rowActions = [
      {
        icon: "fal fa-pen",
        action: arg => {
          if (!this.pairedBreaks) {
            EventBus.$emit("notify", {
              text: "timeclock.need_to_add_breaktime_out",
              color: "warning"
            });
          } else {
            this.modalToggle(arg);
          }
        },
        // This condition apply only for breaks and it hides the button if there is no valid time into this event can be editted
        visibleCondition: (arg, index) => {
          const previous = this.model.timeclock_events[index - 1];
          const next = this.model.timeclock_events[index + 1];
          return previous && next
            ? isAfter(
                new Date(next.registered_at),
                addMinutes(new Date(previous.registered_at), 2)
              )
            : true;
        }
      },
      {
        icon: "fal fa-trash-alt",
        visibleCondition({ item }) {
          return (
            item.event === TimeEventType.BREAK_TIME_IN ||
            item.event === TimeEventType.BREAK_TIME_OUT
          );
        },
        modalActions: {
          modalNumber: 1,
          modalQuestion: this.$t("event_category_delete_confirm").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            this.deleteEvent(arg.item as TimeclockEvent);
            arg.unselectModal();
          },
          modalCancelText: "no"
        }
      }
    ];
  }

  public addEventModal() {
    const rangeValid =
      this.model.timeclock_events.length % 2 ||
      chunk(this.model.timeclock_events, 2)
        .map(e => {
          const top = differenceInMinutes(
            new Date(e[1].registered_at),
            new Date(e[0].registered_at)
          );

          return (top >= 10 && top) || 0;
        })
        .reduce((acc, n) => {
          return acc + n;
        });
    if (this.model && this.model.user && rangeValid) {
      this.modalToggle();
    } else {
      EventBus.$emit("notify", {
        text: "timeclock.cannot_add_events_due_range",
        color: "warning"
      });
    }
  }

  // crud event listener
  public addNewEvent(newValue: TimeclockEvent) {
    this.model.timeclock_events!.push({
      ...newValue,
      temporalId: this.i + 1
    });
    this.i++;
    this.itemForEdition = null;
    this.model.timeclock_events.sort((a: TimeclockEvent, b: TimeclockEvent) => {
      return differenceInMilliseconds(
        new Date(a.registered_at),
        new Date(b.registered_at)
      );
    });
  }

  public editEvent(newValue: TimeclockEvent) {
    const copy = this.model.timeclock_events.map((element: TimeclockEvent) => {
      if (
        (element.id && newValue.id && isEqual(element.id, newValue.id)) ||
        (element.temporalId &&
          newValue.temporalId &&
          isEqual(element.temporalId, newValue.temporalId))
      ) {
        return { ...newValue };
      } else {
        return { ...element };
      }
    });
    this.model.timeclock_events = copy;
  }

  public deleteEvent(eventToDelete: TimeclockEvent) {
    const position = findIndex(
      this.model.timeclock_events!,
      ({ id, temporalId }) =>
        id ? id === eventToDelete.id : temporalId === eventToDelete.temporalId
    );
    const selectedEvent = this.model.timeclock_events[position];
    // If the Event is a BREAK_TIME_IN remove also the breakout event
    if (selectedEvent.event === TimeEventType.BREAK_TIME_IN) {
      this.model.timeclock_events!.splice(position + 1, 1);
      this.model.timeclock_events!.splice(position, 1);
    } else {
      this.model.timeclock_events!.splice(position, 1);
    }
  }

  // button callbacks
  public cancel() {
    this.$router.push({ name: "dashboard" });
  }

  public async saveTimeclockItem() {
    const validator = (this.$refs.form as any).validate();
    if (!this.pairedBreaks) {
      EventBus.$emit("notify", {
        text: "timeclock.need_to_add_breaktime_out",
        color: "warning"
      });
      return;
    } else if (this.model.status !== "CLOSED") {
      EventBus.$emit("notify", {
        text: "timeclock.timeclock_must_be_closed",
        color: "error"
      });
      return;
    }
    this.unpairedBreaksMessage = "";
    if (validator) {
      // if it has an id, its an edition
      this.model.timeclock_events.forEach(element => {
        delete element.temporalId;
        if (!element.id) {
          delete element.id;
        }
      });
      try {
        const changeRequest: TimeclockChangeRequst = {
          timeclock_id: this.model.id!,
          note: this.notes,
          timeclockChangeRequestEvents: this.model.timeclock_events.map(
            ({ event, registered_at }) => {
              return {
                event: event as TimeEventType,
                registered_at: String(registered_at)
              };
            }
          )
        };
        await timeclockService.changeRequest(this.model.id!, changeRequest);
        this.$router.push({
          name: "dashboard"
        });
      } catch (e) {
        EventBus.$emit("notify", {
          text: "timeclock.couldnt_save_timeclock",
          color: "warning"
        });
      }
    }
  }

  // item edition or add
  public modalToggle(itemForEdition?: TimeclockEvent) {
    this.itemForEdition = itemForEdition || null;
    this.$modals
      .load<{ data: TimeclockEvent; type: string }>(
        TimeclockEventModalComponent,
        { size: "fit", positionY: "top" },
        {
          event: this.itemForEdition,
          allEvents: this.model.timeclock_events,
          model: this.model
        }
      )
      .then(data => {
        if (data.type === "new") {
          this.addNewEvent(data.data);
        } else {
          this.editEvent(data.data);
        }
      })
      .catch(() => {
        // No action on close.
      });
  }

  public async deleteTimeclockItem() {
    this.deleteTimeclock(this.model);
  }

  @Watch("searchUserField")
  public async searchData(userName: any) {
    this.debounceUserSearch(userName);
  }

  @Watch("current")
  public updateTable() {
    this.model = this.current!;
    this.model.timeclock_events = this.model.timeclock_events.sort(
      (a: TimeclockEvent, b: TimeclockEvent) => {
        return differenceInMilliseconds(
          new Date(a.registered_at),
          new Date(b.registered_at)
        );
      }
    );
  }

  protected async mounted() {
    this.setPageNav({
      title: "timeclock.change_request",
      rightActions: {
        generalActions: () => this.headerActions
      }
    });

    await this.loadTimeclockData();
    EventBus.$on(
      TimeclockEvents.CREATED,
      async () => await this.loadTimeclockData()
    );
  }

  private async loadTimeclockData() {
    this.setRowActions();
    if (this.$route.params.id && this.currentModel.id === null) {
      await this.findTimeclock(this.$route.params.id);
      this.model = this.current === null ? this.currentModel : this.current;
    } else {
      this.model = this.currentModel;
    }

    this.model.timeclock_events.sort((a: TimeclockEvent, b: TimeclockEvent) => {
      return differenceInMilliseconds(
        new Date(a.registered_at),
        new Date(b.registered_at)
      );
    });
  }
}
